import React from 'react'
import { navigate } from 'gatsby'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

import { usePlanSuggestion } from './usePlanSuggestion'
import { PlanExclusions } from './PlanExclusions'

export const PlanDetails = () => {
  const { currentSelectedPlan, pageType } = usePlanSuggestion()

  return (
    <>
      {currentSelectedPlan() && (
        <div className="plan-details">
          <div className="pt-5 pb-3 container">
            <ReactCSSTransitionGroup
              className="animateContainer"
              transitionName="slide"
              transitionEnterTimeout={500}
              transitionLeaveTimeout={500}
            >
              <div className="pt-2 pb-2 row" key={currentSelectedPlan().name}>
                <div className="col-md-4 d-flex">
                  <div className="plan-price">
                    {currentSelectedPlan().planType == 'Business' && (
                      <div className="price-circle price-circle-plan-table">
                        <div className="amount">
                          $
                          <span>
                            {
                              (
                                currentSelectedPlan().currentPricing.sellInc /
                                100
                              )
                                .toString()
                                .split('.')[0]
                            }
                          </span>
                          {
                            (currentSelectedPlan().currentPricing.sellInc / 100)
                              .toString()
                              .split('.')[1]
                          }
                          <div className="text-bold-secondary">Per Month</div>
                        </div>
                      </div>
                    )}

                    {currentSelectedPlan().planType == 'Personal' && (
                      <div className="price-circle-promo price-circle-plan-table">
                        <div className="amount">
                          $
                          <span>
                            {
                              (
                                currentSelectedPlan().currentPricing.sellInc /
                                100
                              )
                                .toString()
                                .split('.')[0]
                            }
                          </span>
                          <span className="text-month">/MTH</span>
                        </div>
                      </div>
                    )}
                    <p className="text-center">
                      Minimum total cost $
                      {currentSelectedPlan().currentPricing.sellInc / 100}
                    </p>
                  </div>
                </div>
                <div className="col-md-4 d-flex">
                  <div className="plan-info">
                    {pageType === 'mobile' ? (
                      <MobilePlanDetails plan={currentSelectedPlan()} />
                    ) : (
                      <NbnPlanDetails plan={currentSelectedPlan()} />
                    )}
                  </div>
                </div>
                <div className="col-md-4 d-flex">
                  <div className="plan-signup">
                    <a
                      className="btn btn-xl btn-orange btn-accord signup-button"
                      href={
                        pageType === 'mobile'
                          ? `/sim-purchase/?pl=${currentSelectedPlan().code}`
                          : `/sign-up/?pl=${currentSelectedPlan().code}`
                      }
                    >
                      Sign up now
                    </a>
                  </div>
                </div>
              </div>
            </ReactCSSTransitionGroup>
          </div>
          <PlanExclusions />
        </div>
      )}
    </>
  )
}

const NbnPlanDetails = ({ plan }) => {
  const { currentSpeedIndex } = usePlanSuggestion()
  const [uploadEveningSpeed, setUploadEveningSpeed] = React.useState(0)

  React.useEffect(() => {
    console.log(currentSpeedIndex)
    switch (parseInt(currentSpeedIndex)) {
      case 0:
        setUploadEveningSpeed(plan.planType === 'Business' ? 17 : 4)
        break
      case 1:
        setUploadEveningSpeed(17)
        break
      case 2:
        setUploadEveningSpeed(plan.planType === 'Business' ? 21 : 17)
        break
      case 3:
        setUploadEveningSpeed(plan.planType === 'Business' ? 42 : 21)
        break
      case 4:
        setUploadEveningSpeed(42)
        break
      default:
        setUploadEveningSpeed(0)
    }
  }, [plan])

  return (
    <>
      {plan.planType === 'Personal' ? (
        <ul className="list plans nbn-plans">
          <li>
            Typical evening download/upload speed{' '}
            <strong>
              {plan.typicalEveningSpeedInKb / 1024}/{uploadEveningSpeed} Mbps
            </strong>
          </li>
          <NbnPersonalSpeedDetails speed={currentSpeedIndex} />
          <li>Optional VoIP add-on</li>
        </ul>
      ) : (
        <ul className="list plans nbn-plans">
          <li>
            Typical evening download/upload speed{' '}
            <strong>
              {plan.typicalEveningSpeedInKb / 1024}/{uploadEveningSpeed} Mbps
            </strong>
          </li>
          <NbnBusinessSpeedDetails speed={currentSpeedIndex} />
          <li>Includes VoIP with unlimited local, national and mobile calls</li>
          <li>Static IP address</li>
          <li>Optional: nbn Enhanced 12 Resolution SLA</li>
          <li>24/7 Priority Support for Business plans</li>
        </ul>
      )}
    </>
  )
}

const NbnPersonalSpeedDetails = ({ speed }) => (
  <>
    {
      {
        0: (
          <>
            <li>
              NBN speed tier <strong>nbn25/10</strong>
            </li>
            <li>Standard speed for web essentials.</li>
            <li>Surf, email and download music.</li>
            <li>
              Ideal for small to medium households with 3-5 connected devices.
            </li>
          </>
        ),
        1: (
          <>
            <li>
              NBN speed tier <strong>nbn50/20</strong>
            </li>
            <li>
              Standard speeds ideal for streaming music, video and web browsing.
            </li>
            <li>
              Ideal for family homes with 3-5 people using internet at the same
              time.
            </li>
          </>
        ),
        2: (
          <>
            <li>
              NBN speed tier <strong>nbn100/20</strong>
            </li>
            <li>
              Great for internet enthusiasts. Stream like a pro, enjoy more
              media and live gaming.
            </li>
            <li>Ideal for large households with heavy internet usage.</li>
          </>
        ),
        3: (
          <>
            <li>
              NBN speed tier <strong>nbn250/25</strong>
            </li>
            <li>
              Great for internet enthusiasts. Stream like a pro, enjoy more
              media and live gaming.
            </li>
            <li>Ideal for large households with heavy internet usage.</li>
          </>
        ),
        4: (
          <>
            <li>
              NBN speed tier <strong>nbn500-1000/50</strong>
            </li>
            <li>
              Great for internet enthusiasts. Stream like a pro, enjoy more
              media and live gaming.
            </li>
            <li>Ideal for large households with heavy internet usage.</li>
          </>
        ),
      }[speed]
    }
  </>
)

const NbnBusinessSpeedDetails = ({ speed }) => (
  <>
    {
      {
        0: (
          <>
            <li>
              NBN speed tier <strong>nbn50/20</strong>
            </li>
            <li>Standard speed for web essentials.</li>
            <li>Surf, email and download music.</li>
            <li>
              Ideal for small to medium premises with 3-5 connected devices.
            </li>
          </>
        ),
        1: (
          <>
            <li>
              NBN speed tier <strong>nbn100/20</strong>{' '}
            </li>
            <li>
              Standard speeds ideal for streaming music, video and web browsing.
            </li>
            <li>
              Ideal for premises with 3-5 people using internet at the same
              time.
            </li>
          </>
        ),
        2: (
          <>
            <li>
              NBN speed tier <strong>nbn250/25</strong>
            </li>
            <li>
              Great for internet enthusiasts. Stream like a pro, enjoy more
              media and live gaming.
            </li>
            <li>Ideal for large premises with heavy internet usage.</li>
          </>
        ),
        3: (
          <>
            <li>
              NBN speed tier <strong>nbn500-1000/50</strong>
            </li>
            <li>
              Great for internet enthusiasts. Stream like a pro, enjoy more
              media and live gaming.
            </li>
            <li>Ideal for large premises with heavy internet usage.</li>
          </>
        ),
      }[speed]
    }
  </>
)
